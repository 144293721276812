<template>
  <div>
    <div class="invite-coupon-bg">
      <div class="invite-title">您的好友送您浣熊出行</div>
      <div class="invite-desc">新人$100礼包</div>
      <div class="invite-tags">
        <div class="invite-tag">专职司机</div>
        <div class="invite-tag">安全出行</div>
        <div class="invite-tag">品质服务</div>
      </div>

      <div class="invite-pop">
        <div class="invite-pop-box">
          <div class="invite-old-title">老朋友，此优惠券仅限新用户领取</div>
          <div class="invite-old-des">您可以邀请好友赚取优惠券</div>
          <div class="invite-old-btn"
               @click="invite">得奖励</div>
          <div class="invite-old-rule"
               @click="scanRule">了解活动规则</div>
        </div>
      </div>
    </div>

    <van-popup v-model="show"
               :overlay="true"
               class="invite-rule-box">
      <div class="invite-rules">
        <div class="invite-rules-title">活动规则</div>
        <div class="invite-rules-list">
          <ul>
            <li>新用户可通过点击分享链接注册领取浣熊出行$100新人礼包；</li>
            <li>手机号及该设备从未注册过浣熊出行的用户方可领取新人礼包；</li>
            <li>好友完成首单并支付首单费用后，邀请人获得2张$5专车券；</li>
            <li>任何通过不正当手段获得的奖励，浣熊出行有权追回相关奖励或封停账号。</li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Toast } from 'vant'

export default {
  name: 'olduser',
  components: {
    [Popup.name]: Popup
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    invite () {
      Toast(`请点击右上角分享`)
    },
    scanRule () {
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/mixin.less';

.invite {
  &-coupon {
    &-bg {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: url('../../assets/coupon_bg.png') 0 0 no-repeat;
      background-size: cover;
    }
  }

  &-title {
    margin: 80px 60px 10px 0;
    height: 42px;
    font-size: 30px;
    font-family: 'PingFangSC-Medium';
    font-weight: 500;
    color: rgba(36, 133, 145, 1);
    line-height: 42px;
    text-align: right;
  }

  &-desc {
    margin: 10px 60px 10px 0;
    height: 80px;
    font-size: 56px;
    font-family: 'PingFangSC-Semibold';
    font-weight: 600;
    color: rgba(36, 133, 145, 1);
    line-height: 80px;
    text-align: right;
  }

  &-tags {
    margin: 20px 60px 0px auto;
    display: flex;
    width: 350px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
  }
  &-tag {
    width: 110px;
    height: 40px;
    background: rgba(36, 133, 145, 1);
    border-radius: 30px;
    font-size: 20px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 40px;
    text-align: center;
  }

  &-pop {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;

    &-box {
      width: 690px;
      height: 428px;
      margin: 0 auto;
      padding: 80px 0 60px;
      background: rgba(255, 255, 255, 1);
      border-radius: 20px;
      box-sizing: border-box;
    }
  }

  &-old {
    text-align: center;

    &-title {
      margin-bottom: 10px;
      font-size: 32px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 44px;
      text-align: center;
    }

    &-des {
      font-size: 26px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 36px;
      text-align: center;
    }

    &-btn {
      width: 440px;
      height: 90px;
      margin: 40px auto;
      background: rgba(12, 170, 50, 1);
      box-shadow: 0px 8px 22px 0px rgba(12, 170, 50, 0.4);
      border-radius: 44px;
      font-size: 30px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 90px;
      text-align: center;
    }

    &-rule {
      font-size: 20px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(12, 170, 50, 1);
      line-height: 28px;
      text-align: center;
    }
  }

  &-rules {
    width: 690px;
    height: 596px;
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 60px 30px;
    box-sizing: border-box;

    &-title {
      margin: 0 auto 30px;
      text-align: center;
      font-size: 36px;
      font-family: 'PingFangSC-Medium';
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 50px;
    }

    &-list {
      font-size: 30px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      ul {
        li {
          margin-bottom: 20px;
          padding-inline-start: 50px;
          background: url('../../assets/rule_icon.png') 4px 10px no-repeat;
          background-size: 22px 22px;
          font-size: 30px;
          font-family: 'PingFangSC-Regular';
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 42px;
        }
      }
    }

  }
}
.invite-rule-box {
  border-radius: 20px;
}
</style>